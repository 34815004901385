<template>
    <v-dialog :retain-focus="false" v-model="show" persistent max-width="600px">
        <v-card :loading="loading">
            <v-card-title class="text-h5">
                <v-btn
                    class="mr-3"
                    depressed
                    color="primary"
                    icon
                    rounded
                    @click="closeDialog"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>

                {{ title }}
                <v-spacer></v-spacer>
                <v-btn
                    v-if="!createForm"
                    depressed
                    color="primary"
                    rounded
                    icon
                    class=""
                    @click="update"
                >
                    <v-icon>mdi-lead-pencil</v-icon>
                </v-btn>
            </v-card-title>

            <v-card-text>
                <v-form
                    ref="formClient"
                    v-model="valid"
                    :disabled="!createForm && !isEditing"
                >
                    <v-row justify="center">
                        <input
                            v-if="createForm || isEditing"
                            ref="profilePic"
                            name="profilePic"
                            accept="image/*"
                            type="file"
                            class="d-none"
                            @change="onFilePicked"
                        />
                        <v-hover v-slot:default="{ hover }">
                            <v-avatar
                                color="primary"
                                size="96"
                                class="avatar"
                                @click="$refs.profilePic.click()"
                            >
                                <v-img
                                    contain
                                    aspect-ratio="1/1"
                                    v-if="client.profilePicURL || image"
                                    :src="client.profilePicURL || image"
                                >
                                </v-img>
                                <v-icon
                                    v-else
                                    :size="!hover ? 66 : null"
                                    color="white"
                                >
                                    {{
                                        hover
                                            ? 'mdi-camera'
                                            : 'mdi-office-building-outline'
                                    }}
                                </v-icon>
                            </v-avatar>
                        </v-hover>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field
                                v-model="client.name"
                                :readonly="!createForm && !isEditing"
                                :rules="[rules.required]"
                                prepend-icon="mdi-office-building-outline"
                                label="Name *"
                                required
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="6">
                            <v-text-field
                                v-if="!createForm && !isEditing"
                                :readonly="!createForm && !isEditing"
                                v-model="client.country"
                                prepend-icon="mdi-earth"
                                label="Country "
                                required
                            />
                            <v-autocomplete
                                v-if="createForm || isEditing"
                                v-model="client.country"
                                :items="this.countries"
                                :rules="[rules.required]"
                                prepend-icon="mdi-earth"
                                label="Country *"
                                @change="selectState"
                                required
                            />
                        </v-col>
                        <v-col cols="6">
                            <v-text-field
                                v-if="this.states.length == 0"
                                v-model="client.state"
                                :readonly="!createForm && !isEditing"
                                prepend-icon="mdi-map-marker-radius"
                                label="State "
                                required
                            />
                            <v-autocomplete
                                v-if="
                                    (createForm || isEditing) &&
                                        this.states.length > 0
                                "
                                v-model="client.state"
                                :rules="[rules.required]"
                                @change="selectCity"
                                :items="this.states"
                                prepend-icon="mdi-map-marker-radius"
                                label="State *"
                                required
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="6">
                            <v-text-field
                                v-if="this.cities.length == 0"
                                :readonly="!createForm && !isEditing"
                                v-model="client.city"
                                prepend-icon="mdi-city"
                                label="City"
                                required
                            />
                            <v-combobox
                                v-if="
                                    (createForm || isEditing) &&
                                        this.cities.length > 0
                                "
                                v-model="client.city"
                                :filter="filter"
                                :hide-no-data="!search"
                                :items="this.cities"
                                hide-details
                                prepend-icon="mdi-city"
                                :search-input.sync="search"
                                hide-selected
                                label="City *"
                                required
                                flat
                            >
                                <template v-slot:item="{ index, item }">
                                    <v-text-field
                                        v-if="editing === item"
                                        v-model="editing.text"
                                        autofocus
                                        flat
                                        background-color="transparent"
                                        hide-details
                                        solo
                                        @keyup.enter="edit(index, item)"
                                    ></v-text-field>
                                    <span v-else>
                                        {{ item.text }}
                                    </span>
                                    <v-spacer></v-spacer>
                                </template>
                            </v-combobox>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field
                                v-if="!createForm && !isEditing"
                                v-model="client.language"
                                :readonly="!createForm && !isEditing"
                                prepend-icon="mdi-account-tie-voice"
                                label="Language *"
                                required
                            />
                            <v-select
                                v-if="createForm || isEditing"
                                :items="itemsLanguage"
                                :rules="[rules.required]"
                                v-model="client.language"
                                prepend-icon="mdi-account-tie-voice"
                                label="Language *"
                                required
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="6">
                            <v-text-field
                                v-if="!createForm && !isEditing"
                                v-model="client.currency"
                                :readonly="!createForm && !isEditing"
                                prepend-icon="mdi-currency-usd"
                                label="Currency"
                                required
                            />
                            <v-autocomplete
                                v-if="createForm || isEditing"
                                v-model="client.currency"
                                @change="checkCurrency"
                                :items="itemsCurrency"
                                :rules="[rules.required]"
                                prepend-icon="mdi-currency-usd"
                                label="Currency *"
                                required
                            />
                        </v-col>
                        <v-col cols="6">
                            <v-text-field
                                v-model="client.note"
                                :readonly="!createForm && !isEditing"
                                prepend-icon="mdi-note-multiple"
                                label="Note"
                            />
                        </v-col>
                    </v-row>
                </v-form>

                <small v-if="createForm">* indicates required field</small>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    v-if="createForm"
                    text
                    color="primary"
                    :loading="loading"
                    :disabled="!valid || error"
                    @click="saveClient"
                >
                    Save
                </v-btn>

                <v-btn
                    v-if="isEditing"
                    text
                    :disabled="!valid || error"
                    color="primary"
                    :loading="loading"
                    @click="saveClient"
                >
                    Save
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { storage } from '@/services/firebase'
import { mapState, mapMutations } from 'vuex'
import API from '@/services/api'
import _ from 'lodash'
const countrycitystate = require('countrycitystatejson')
import codesCurrency from '@/assets/codes-all_json.json'
export default {
    name: 'ClientForm',
    props: {
        title: {
            type: String,
            required: true,
        },
        originalClient: {
            type: Object,
            default: null,
        },
        value: Boolean,
        client: {
            type: Object,
            default() {
                return {
                    id: '',
                    name: '',
                    country: '',
                    city: '',
                    note: '',
                    currency: '',
                    language: '',
                    picture: '',
                }
            },
        },

        createForm: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            itemsLanguage: ['English', 'Spanish'],
            itemsCurrency: null,
            company: JSON.parse(localStorage.getItem('company')),
            folder: 'clients_pictures',
            loading: false,
            loadingPicture: false,
            profilePic: null,
            error: false,
            exchanges: [],
            errorMsg: null,
            settingExchange: [],
            image: null,
            editing: null,
            editingIndex: -1,
            search: null,
            countries: [],
            cities: [],
            states: [],
            shortNameCity: null,
            listCountries: null,
            valid: false,
            isEditing: false,
            rules: {
                required: v => !!v || 'Required',
                email: v => /.+@.+\..+/.test(v) || 'Not a valid E-mail',
                city: v => /^[A-Za-z ]+$/.test(v) || 'Not a valid input',
            },
            deleteDialog: false,
            deleteError: false,
        }
    },
    computed: {
        clientDiff: function() {
            if (!this.createForm) {
                return this.objDiff(this.originalClient, this.client)
            } else {
                return null
            }
        },
        ...mapState(['settings']),
        show: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            },
        },
    },
    watch: {
        settings(newValue) {
            if (newValue.length > 0) {
                this.setSettings()
            }
        },
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        objDiff: function(origObj, newObj) {
            function changes(newObj, origObj) {
                let arrayIndexCounter = 0
                return _.transform(newObj, function(result, value, key) {
                    if (!_.isEqual(value, origObj[key])) {
                        const resultKey = _.isArray(origObj)
                            ? arrayIndexCounter++
                            : key
                        result[resultKey] =
                            _.isObject(value) && _.isObject(origObj[key])
                                ? changes(value, origObj[key])
                                : value
                    }
                })
            }
            return changes(newObj, origObj)
        },
        update: function() {
            this.isEditing = !this.isEditing
            this.selectState(this.client.country)
            this.selectCity(this.client.state)
        },
        selectCity: function(event) {
            this.cities[0] = { header: 'Select or create one' }
            let data = countrycitystate.getCities(this.shortNameCity, event)
            if (data.length == 0) {
                this.cities = []
            }
            data.forEach(city => {
                this.cities.push({ text: city })
            })
        },
        checkCurrency(event) {
            const index = this.exchanges.findIndex(u => u.currency == event)
            if (index < 0) {
                this.error = true
                this.errorMsg =
                    'This currency cannot be selected because it is not created in /settings/exchange. Please change it or go to seetings to create it.'
                this.setErrorItems(this.errorMsg)
            } else {
                this.error = false
                this.errorMsg = null
            }
        },
        filter(item, queryText, itemText) {
            if (item.header) return false
            const hasValue = val => (val != null ? val : '')
            const text = hasValue(itemText)
            const query = hasValue(queryText)

            return (
                text
                    .toString()
                    .toLowerCase()
                    .indexOf(query.toString().toLowerCase()) > -1
            )
        },
        edit(index, item) {
            if (!this.editing) {
                this.editing = item
                this.editingIndex = index
            } else {
                this.editing = null
                this.editingIndex = -1
            }
        },
        selectState: function(event) {
            const index = this.listCountries.findIndex(x => x.name === event)
            this.shortNameCity = this.listCountries[index].shortName
            this.states = countrycitystate.getStatesByShort(this.shortNameCity)
        },
        onFilePicked: function(event) {
            this.client.profilePicURL = URL.createObjectURL(
                event.target.files[0]
            )
            this.client.picture =
                this.client.profilePicURL.split('/').pop() + '.jpg'
            this.image = this.client.profilePicURL
        },
        loadImage: function(src) {
            return new Promise(resolve => {
                const img = new Image()
                img.src = src
                img.onload = () => resolve(img)
            })
        },
        toBlob: function(canvas) {
            return new Promise(resolve => {
                canvas.toBlob(blob => resolve(blob), 'image/jpeg', 0.8)
            })
        },
        resizeImage: async function(img) {
            const canvas = document.createElement('canvas')
            let { height, width } = img
            const MAX = 250
            if (height > width) {
                if (height > MAX) {
                    width *= MAX / height
                    height = MAX
                }
            } else {
                if (width > MAX) {
                    height *= MAX / width
                    width = MAX
                }
            }
            canvas.height = height
            canvas.width = width
            const ctx = canvas.getContext('2d')
            ctx.drawImage(img, 0, 0, width, height)
            const blob = await this.toBlob(ctx.canvas)
            const name = img.src.split('/').pop() + '.jpg'
            const file = new File([blob], name, { type: 'image/jpeg' })
            return file
        },
        uploadImage: async function(file) {
            const path = `${this.company}/${this.folder}`
            const storageRef = storage().ref(path)
            const clientPicRef = storageRef.child(file.name)
            const result = await clientPicRef.put(file)
            return result
        },
        deleteImage: async function(img) {
            const path = `${this.company}/${this.folder}`
            const storageRef = storage().ref(path)
            const refOldPictture = storageRef.child(img)
            await refOldPictture.delete()
            return true
        },
        closeDialog: function() {
            this.$emit('closeDialog')
            this.$refs.formClient.reset()
            this.error = false
            this.errorMsg = null
            this.isEditing = false
        },
        saveClient: function() {
            if (this.createForm) {
                this.createClient()
            } else {
                this.updateClient()
            }
        },
        updateClient: async function() {
            try {
                this.loading = true
                this.error = false
                this.errorMsg = null
                const { id } = this.client
                if (this.clientDiff.profilePicURL) {
                    const img = await this.loadImage(this.client.profilePicURL)
                    const imgResize = await this.resizeImage(img)
                    if (this.originalClient.picture) {
                        await this.deleteImage(this.originalClient.picture)
                    }
                    await this.uploadImage(imgResize)
                }
                if (this.client.city && this.client.city.text) {
                    this.client.city = this.client.city.text
                }
                delete this.clientDiff.profilePicURL
                const {
                    data: { client },
                } = await API.updateClient(id, this.clientDiff)
                if (client.picture) {
                    client.profilePicURL = this.client.profilePicURL
                }
                client.projects = this.client.projects
                client.contacts = this.client.contacts
                client.companyRoles = this.client.companyRoles
                this.$emit('replaceClient', id, client)
                this.closeDialog()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        setSettings() {
            const indexExchanges = this.settings.findIndex(
                u => u.name === 'Exchange'
            )
            this.settingExchange = this.settings[indexExchanges]
            this.exchanges = this.settingExchange.exchanges
        },
        createClient: async function() {
            try {
                this.loading = true
                this.error = false
                this.errorMsg = null
                if (this.client.profilePicURL) {
                    const img = await this.loadImage(this.client.profilePicURL)
                    const imgResize = await this.resizeImage(img)
                    await this.uploadImage(imgResize)
                }
                if (this.client.city) {
                    this.client.city = this.client.city.text
                }

                this.client.enabled = true

                const {
                    data: { client },
                } = await API.createClient(this.client)

                if (client.picture) {
                    client.profilePicURL = this.client.profilePicURL
                }
                this.$emit('addClient', client)
                this.closeDialog()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
    },
    async created() {
        this.listCountries = countrycitystate.getCountries()
        this.countries = this.listCountries.map(x => x.name)
        this.itemsCurrency = codesCurrency.map(x => x.AlphabeticCode)
        if (this.settings.length > 0) {
            this.setSettings()
        }
    },
}
</script>
